import React, { FC } from 'react';
import styled from 'styled-components';

import Card from '../../ui/card/card';
import Text from '../../ui/text/text';
import { darkColors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { BASE_FONT_SIZE } from '../../constants/layout';
import { useShadow, useInsetShadow, useCodeTypography } from '../../utils/mixins';

import MacOSButtons from './mac-os-buttons';

const PADDING = 12;

export type CodeProps = {
  children: string,
  className: string,
  line?: string,
  file?: string,
};

const Container = styled(Card)`
  ${useShadow()}

  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${rem(40)} 0;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: auto;
`;

const StyledFile = styled(Text)`
  padding: ${rem(4)} ${rem(PADDING)};
`;

const Language = styled(Text)`
  padding: ${rem(7)} ${rem(PADDING)};
`;

const Pre = styled.pre`
  && {
    ${useInsetShadow({ color: darkColors.box.foreground })}

    flex-grow: 1;
    margin: 0;
    padding: 0;
    padding-bottom: ${rem(PADDING)};
    background-color: inherit;
    font-size: ${rem(BASE_FONT_SIZE)};
  }
`;

const StyledCode = styled.code`
  &&& {
    ${useCodeTypography()}

    flex-grow: 1;
    padding: 0 ${rem(PADDING)};
  }
`;

const Code: FC<CodeProps> = ({ children, className, line, file }: CodeProps) => (
  <Container color={darkColors.box.foreground}>
    <Header>
      <MacOSButtons />
      {file && (
        <StyledFile center={true} color={darkColors.text.quote} size={13}>
          {decodeURI(file)}
        </StyledFile>
      )}
      <Language color={darkColors.text.secondary} size={11}>
        {className.replace(/language-/, '').toUpperCase()}
      </Language>
    </Header>
    <Pre data-line={line}>
      <StyledCode className={className}>
        {children}
      </StyledCode>
    </Pre>
  </Container>
);

export default Code;
