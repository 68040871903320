import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../utils/colors';
import { betweenMinMax } from '../../utils/number';
import { rem } from '../../utils/converters';
import { useTransition } from '../../utils/mixins';

export type ScrollIndicatorProps = {
  /**
   * The index of the parent element to detect scroll change from.
   * Must be greater than or equal to 1.
   */
  level?: number,
  onChange?: (percent: number) => void,
  parentName?: string,
};

type ProgressBarProps = {
  _percent: number
};

/**
 * It does not make sense to track scroll if the entire
 * parent element can be fully visible on the screen.
 */
const shouldSetScrollListener = (parent: HTMLElement) => {
  const { height } = parent.getBoundingClientRect();
  return height > document.documentElement.clientHeight;
};

const CONTAINER_WIDTH = 2;
const BORDER_RADIUS = CONTAINER_WIDTH / 2;

const Container = styled.div`
  ${useTransition()}

  height: 100%;
  width: ${rem(CONTAINER_WIDTH)};
  min-width: ${rem(CONTAINER_WIDTH)};
  border-radius: ${rem(BORDER_RADIUS)};
  background-color: ${colors.box.foreground};
`;

const ProgressBar = styled.div.attrs<ProgressBarProps>(({ _percent }) => ({
  style: {
    height: `${_percent}%`,
  },
}))<ProgressBarProps>`
  ${/* height should not be transitioned */''}
  ${useTransition({ property: 'background-color' })}

  width: 100%;
  border-radius: ${rem(BORDER_RADIUS)};
  background-color: ${colors.components.scroll_indicator.color};
`;

const getParent = (
  parent: HTMLElement | null | undefined,
  level: number
): HTMLElement | null | undefined => (
  level === 1 ? parent : getParent(parent?.parentElement, level - 1)
);

const ScrollIndicator: FC<ScrollIndicatorProps> = ({
  level = 1,
  onChange,
  parentName = 'article',
}: ScrollIndicatorProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [percent, setPercent] = useState(0);
  const percent10by10 = Math.floor(percent / 10) * 10;

  useEffect(() => {
    const parent = getParent(ref?.current?.parentElement, level);
    if (parent && shouldSetScrollListener(parent)) {
      const setScrollPercent = () => {
        /**
         * We get the parent height again here because it seems to be a
         * bit smaller on the initial render than on the following ones.
         */
        const { height, top } = parent.getBoundingClientRect();

        const scroll = -1 * top;
        const scrollPercent = (scroll / height) * 100;

        setPercent(betweenMinMax(scrollPercent, 0, 100));
      };
      window.onscroll = setScrollPercent;

      return () => {
        window.onscroll = null;
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(percent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  /**
   * The scroll indicator only appears as a progress bar if percent is greater than 0
   */
  const domAttrs = percent > 0
    ? {
      role: 'progressbar',
      'aria-valuemin': 0,
      'aria-valuemax': 100,
      'aria-valuenow': percent10by10,
      'aria-label': `You are at ${percent10by10 > 0 ? `${percent10by10} percents` : 'the beginning'} of the ${parentName}`,
    } : {
      role: 'presentation',
    };

  return (
    <Container ref={ref}>
      <ProgressBar
        _percent={percent}
        {...domAttrs}
      />
    </Container>
  );
};

export default memo(ScrollIndicator);
