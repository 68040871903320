import React from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';

const CONTAINER_PADDING = 12;
const BUTTON_MARGIN_RIGHT = 8;
const BUTTON_SIZE = 10;

const Container = styled.div`
  display: flex;
  padding: ${rem(CONTAINER_PADDING)};
  padding-right: ${rem(CONTAINER_PADDING - BUTTON_MARGIN_RIGHT)};
`;

const Button = styled.span`
  width: ${rem(BUTTON_SIZE)};
  height: ${rem(BUTTON_SIZE)};
  border-radius: ${rem(BUTTON_SIZE)};
  margin-right: ${rem(BUTTON_MARGIN_RIGHT)};
`;

const Close = styled(Button)`
  background-color: #f53b3d;
`;

const Minimize = styled(Button)`
  background-color: #fab025;
`;

const FullScreen = styled(Button)`
  background-color: #32c231;
`;

const MacOSButtons = () => (
  <Container role={'presentation'}>
    <Close />
    <Minimize />
    <FullScreen />
  </Container>
);

export default MacOSButtons;
