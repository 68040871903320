import styled from 'styled-components';

import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { useBackgroundColor } from '../../utils/mixins';

const Note = styled.aside.attrs({
  role: 'note',
})`
  ${useBackgroundColor({ color: colors.box.foreground })}

  padding: ${rem(10)} ${rem(18)};
  border-left: ${rem(2)} solid ${colors.text.secondary};
  border-top-right-radius: ${rem(12)};
  border-bottom-right-radius: ${rem(12)};
`;

export default Note;
