export const betweenMinMax = (
  value: number,
  min: number,
  max: number
) => Math.max(min, Math.min(max, value));

export const round = (n: number, decimalPlaces: number = 1) => {
  const m = 10 ** decimalPlaces;
  return Math.floor(n * m) / m;
};
