import { useLayoutEffect } from 'react';
import { highlightAll } from 'prismjs';

const usePrism = () => {
  useLayoutEffect(() => {
    highlightAll();
  });
};

export default usePrism;
