import React, { FC } from 'react';
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import usePrism from '../../hooks/use-prism';
import Title from '../../ui/title/title';
import AppContainer from '../app-container/app-container';
import { rem } from '../../utils/converters';
import Code from '../code/code';
import Emoji from '../emoji/emoji';
import ExternalLink from '../../ui/external-link/external-link';
import Note from '../../ui/note/note';
import InlineCode from '../inline-code/inline-code';
import Keyword from '../../ui/keyword/keyword';
import Blockquote from '../../ui/blockquote/blockquote';
import { ArticlePage } from '../../types/article';
import { Query } from '../../types/query';
import ArticleDetails from '../article/article-details';
import ArticleSummary from '../article-summary';

import { ul, h2, h3 } from './components';

const shortcodes: MDXProviderComponentsProp = {
  a: ExternalLink,
  blockquote: Blockquote,
  code: Code,
  h2,
  h3,
  inlineCode: InlineCode,
  pre: React.Fragment, // pre tag is already set in code.tsx
  ul,

  // Custom components
  ExternalLink,
  Emoji,
  InlineCode,
  K: Keyword,
  Note,
}; // as Components

const Article = styled.article`
  margin: ${rem(48)} 0;
`;

const ArticleTitle = styled(Title)`
  padding-bottom: 0;
`;

const ArticleContainer: FC<Query<ArticlePage>> = ({ data }: Query<ArticlePage>) => {
  usePrism();

  const {
    mdx: {
      body,
      frontmatter: {
        title,
        tags,
        date,
      },
    },
  } = data;

  return (
    <AppContainer>
      <ArticleTitle level={1}>
        {title}
      </ArticleTitle>
      <ArticleDetails date={date} tags={tags} />
      <Article>
        <ArticleSummary />
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {body}
          </MDXRenderer>
        </MDXProvider>
      </Article>
    </AppContainer>
  );
};

export const pageQuery = graphql`
  query ArticleById($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        tags
        date(formatString: "MMMM YYYY")
      }
    }
  }
`;

export default ArticleContainer;
