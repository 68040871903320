import styled from 'styled-components';

import { colors, darkColors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { useBackgroundColor, useCodeTypography } from '../../utils/mixins';

export type InlineCodeProps = {
  language?: string,
};

const InlineCode = styled.code.attrs<InlineCodeProps>(({ language }) => ({
  className: language ? `language-${language}` : undefined,
}))<InlineCodeProps>`
  ${/**
    color is not part of the &&& selector so that
    prism can override it if any language prop is provided
  */''}
  color: ${colors.components.inline_code.color};

  &&& {
    ${useCodeTypography()}
    ${({ language }) => useBackgroundColor({ color: language ? darkColors.box.foreground : colors.components.inline_code.background })}

    padding: ${rem(3)};
    margin: 0 ${rem(3)};
    border-radius: ${rem(4)};
    white-space: nowrap;
  }
`;

export default InlineCode;
