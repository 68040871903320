import React, { FC } from 'react';
import styled from 'styled-components';

import { TOGGLE_BUTTON_SIZE } from '../../constants/layout';
import Link from '../../ui/link/link';
import Title, { TitleProps } from '../../ui/title/title';
import { getAnchorLink } from '../../utils/article';
import { rem } from '../../utils/converters';
import { dashCase } from '../../utils/string';
import { APP_CONTAINER_PADDING } from '../app-container/app-container';

export const H2_TOP_PADDING = 26;

export const ul = styled.ul`
  list-style-position: inside;
  margin-inline-start: ${rem(16)};
`;

export const h3 = styled(Title).attrs({
  level: 3,
})`
  padding: ${rem(10)} 0;
`;

const H2Container = styled.span`
  display: flex;
  align-items: baseline;
`;

const StyledLink = styled(Link)`
  font-size: ${rem(14)};
  padding-right: ${rem(16)};
`;

const Wrapper = styled.div`
  flex-grow: 1;
`;

const StyledTitle = styled(Title)`
  margin-top: ${rem(10)};
  padding-top: ${rem(H2_TOP_PADDING)};
  padding-bottom: ${rem(16)};
`;

const ArticleSummaryBurgerButtonSafeArea = styled.div`
  float: right;
  width: ${rem(TOGGLE_BUTTON_SIZE - APP_CONTAINER_PADDING)};
  height: ${rem(TOGGLE_BUTTON_SIZE)};
`;

export const h2: FC<TitleProps> = ({
  children,
  ...rest
}: TitleProps) => {
  // sets the id of each title as an #anchor for ArticleSummary
  const id = typeof children === 'string' ? dashCase(children) : undefined;

  return (
    <H2Container>
      {!!id && (
        <StyledLink to={getAnchorLink(id)}>
          🔗
        </StyledLink>
      )}
      <Wrapper>
        <ArticleSummaryBurgerButtonSafeArea role={'presentation'} />
        <StyledTitle {...rest} id={id}>
          {children}
        </StyledTitle>
      </Wrapper>
    </H2Container>
  );
};
