import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';
import { BASE_FONT_SIZE } from '../../constants/layout';
import { useFont } from '../../utils/mixins';

type TitleOwnProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6,
};

type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type TitleProps = HTMLAttributes<HTMLHeadingElement> & PropsWithChildren<TitleOwnProps>;

const StyledTitle = styled.h2`
  ${useFont({ primary: true })}
  position: relative;
  margin: 0;
  margin-top: ${rem(4)};
  padding: ${rem(2 * BASE_FONT_SIZE)} 0;
`;

const Title: FC<TitleProps> = ({
  children,
  className,
  // used, among others, as an anchor for ArticleSummary
  id,
  level,
}: TitleProps) => (
  <StyledTitle
    as={level ? `h${level}` as TitleTag : undefined}
    className={className}
    id={id}
  >
    {children}
  </StyledTitle>
);

export default Title;
