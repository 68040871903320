import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { APP_CONTAINER_MAX_WIDTH, TOGGLE_BUTTON_SIZE } from '../../constants/layout';
import {
  ARTICLE_SUMMARY_OPEN_MOBILE,
  ARTICLE_SUMMARY_HIDDEN,
  ARTICLE_SUMMARY_VISIBLE,
  ARTICLE_SUMMARY_BUTTON_VISIBLE,
  ARTICLE_SUMMARY_BUTTON_HIDDEN,
} from '../../constants/z-index';
import { GreyTextLink } from '../../ui/text-link/text-link';
import { rem } from '../../utils/converters';
import { colors } from '../../utils/colors';
import { H2_TOP_PADDING } from '../article-container/components';
import ScrollIndicator from '../../ui/scroll-indicator';
import { min, useInsetShadow, useTransition, useBackgroundColor } from '../../utils/mixins';
import useToggle from '../../hooks/use-toggle';
import { APP_CONTAINER_PADDING } from '../app-container/app-container';
import BurgerButton from '../../ui/burger-button/burger-button';
import { getAnchorLink } from '../../utils/article';

const TOP = H2_TOP_PADDING;
const DESKTOP_WIDTH = `calc((100vw - ${rem(APP_CONTAINER_MAX_WIDTH)}) / 2)`;
const BOTTOM_SPACE_FOR_SCROLLBAR = 10;
const LINK_FONT_SIZE = 13;
/**
 * The height of a span with a 13px text is 16px. Apparently,
 * this extra space seems to be 1/4 of the text font size.
 */
const LINK_EXTRA_VERTICAL_SPACE = Math.ceil(LINK_FONT_SIZE / 4);
const LINE_HEIGHT = 1;
/**
 * "white-space: nowrap" will force each link to fit on one line.
 * The height of the link element will equal to
 * LINK_FONT_SIZE * LINE_HEIGHT + a little quantity that depends
 * on the font size.
 * For example, the height of a link with a 13px text is 16px.
 */
const LINK_HEIGHT = (LINK_FONT_SIZE * LINE_HEIGHT) + LINK_EXTRA_VERTICAL_SPACE;

type ToggleButtonProps = {
  _visible: boolean
};

const ToggleButton = styled(BurgerButton)<ToggleButtonProps>`
  ${useTransition()}

  position: fixed;
  top: 0;
  right: 0;

  ${({ _visible }) => `
    opacity: ${_visible ? '1' : '0'};
    z-index: ${_visible ? ARTICLE_SUMMARY_BUTTON_VISIBLE : ARTICLE_SUMMARY_BUTTON_HIDDEN};
  `}

  ${min.l`
    display: none;
  `}
`;

type ContainerProps = {
  _open: boolean
  _visible: boolean
};

const Container = styled.div<ContainerProps>`
  overflow: visible;
  line-height: ${LINE_HEIGHT};

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${rem(2 * APP_CONTAINER_PADDING)} ${rem(APP_CONTAINER_PADDING)};
  ${useBackgroundColor({ color: colors.components.menu.background })};

  ${({ _open, _visible }) => `
    opacity: ${_open && _visible ? '1' : '0'};
    z-index: ${_open && _visible ? ARTICLE_SUMMARY_OPEN_MOBILE : ARTICLE_SUMMARY_HIDDEN};
    pointer-events: ${_open && _visible ? 'auto' : 'none'};
  `}
  
  ${min.l`
    display: block;
    position: sticky;
    top: ${rem(TOP)};
    left: 100%;
    right: auto;
    bottom: auto;
    padding: 0;
    background-color: transparent;

    ${({ _visible }: ContainerProps) => `
      opacity: ${_visible ? '1' : '0'};
      z-index: ${ARTICLE_SUMMARY_VISIBLE};
      pointer-events: ${_visible ? 'auto' : 'none'};
    `}

    width: ${rem(1)};
    height: ${rem(1)};
  `}
`;

type ContentProps = {
  _height?: number,
};

const Content = styled.div<ContentProps>`
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0;

  min-height: 50vh;
  ${({ _height }) => _height && `height: ${rem(_height)};`}

  ${min.l`
    width: ${DESKTOP_WIDTH};
    max-width: ${DESKTOP_WIDTH};
  `}
`;

const ScrollIndicatorContainer = styled.div`
  margin: 0 ${rem(16)} ${rem(BOTTOM_SPACE_FOR_SCROLLBAR)};
`;

const SummaryContainer = styled.div`
  padding-bottom: ${rem(BOTTOM_SPACE_FOR_SCROLLBAR)};

  ${useInsetShadow({ color: 'transparent' })}

  ${
  /*
   * the inset shadow is not set on mobile because the background
   * is slightly transparent, which makes it visible.
   */
  min.l`
    ${useInsetShadow()}
  `}
`;

const TitlesContainer = styled.div`
  height: 100%;
`;

type LinkContainerProps = {
  _height?: number,
};

const LinkContainer = styled.div<LinkContainerProps>`
  ${({ _height }) => _height && `
    height: ${_height}%;
  `}
`;

const StyledTextLink = styled(GreyTextLink)`
  white-space: nowrap;
`;

const getTitleHeight = (
  parent: HTMLElement
) => (
  title: HTMLHeadingElement,
  i: number,
  titles: HTMLHeadingElement[]
) => {
  /**
   * The last link will compare its top to the bottom of the article container
   */
  const nextElementPosition = i === titles.length - 1
    ? parent.offsetTop + parent.clientHeight
    : titles[i + 1].offsetTop;

  const distanceFromNextLink = nextElementPosition - title.offsetTop;
  const exactPercent = (distanceFromNextLink / parent.clientHeight) * 100;

  return exactPercent;
};

const getContentHeight = (titlesHeight: number[]) => {
  /**
   * The minimum percentage among titles height.
   */
  const minTitleHeightInPercents = titlesHeight.reduce(
    (acc: number, cur: number) => Math.min(acc, cur),
    100
  );

  // cross product
  const containerHeightInPixels = (100 * LINK_HEIGHT) / minTitleHeightInPercents;
  return containerHeightInPixels;
};

const ArticleSummary = () => {
  const ref = useRef<HTMLDivElement>(null);
  const parent = ref?.current?.parentElement;

  const [open, toggle] = useToggle(false);
  const [visible, setVisible] = useState(false);
  const [titles, setTitles] = useState<HTMLHeadingElement[]>([]);

  /**
   * An array of numbers, representing the percentage of the
   * parent's height the section of a given title equals to.
   */
  const titlesHeight: number[] = parent
    ? titles.map(getTitleHeight(parent))
    : [];
  const contentHeight = titlesHeight.length > 0 ? getContentHeight(titlesHeight) : 0;

  useEffect(() => {
    setTitles(Array.from(document.documentElement.getElementsByTagName('h2')));
  }, []);

  const handleChange = useCallback((percent) => {
    setVisible(!!(percent % 100));
  }, []);

  const buttonTitle = `${open ? 'Hide' : 'Show'} article summary`;

  return (
    <>
      <ToggleButton
        _visible={visible}
        onClick={toggle}
        open={open}
        size={TOGGLE_BUTTON_SIZE}
        title={buttonTitle}
      />
      <Container
        onClick={toggle}
        ref={ref}
        data-testid={'container'}
        _open={open}
        _visible={visible}
      >
        <Content
          data-testid={'content'}
          _height={contentHeight}
        >
          <ScrollIndicatorContainer>
            <ScrollIndicator
              level={4}
              onChange={handleChange}
            />
          </ScrollIndicatorContainer>
          <SummaryContainer>
            <TitlesContainer>
              {titles.map((title, i) => {
                const anchor = title.getAttribute('id');
                if (!anchor) return null;

                const link = getAnchorLink(anchor);
                const titleHeight = titlesHeight[i];
                return (
                  <LinkContainer
                    key={link}
                    _height={titleHeight}
                  >
                    <StyledTextLink
                      color={colors.text.grey}
                      size={LINK_FONT_SIZE}
                      to={link}
                    >
                      {title.outerText}
                    </StyledTextLink>
                  </LinkContainer>
                );
              })}
            </TitlesContainer>
          </SummaryContainer>
        </Content>
      </Container>
    </>
  );
};

export default memo(ArticleSummary);
