import styled from 'styled-components';

import { colors } from '../../utils/colors';
import Text from '../text/text';

const Keyword = styled(Text).attrs({
  color: colors.text.secondary,
  primary: true,
})``;

export default Keyword;
