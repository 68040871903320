import styled from 'styled-components';

import { colors } from '../../utils/colors';
import Note from '../note/note';

const Blockquote = styled(Note).attrs({
  as: 'blockquote',
})`
  font-style: italic;
  color: ${colors.text.quote};

  &, p {
    margin: 0;
  }
`;

export default Blockquote;
