import { ArticleNode } from '../../types/article';
import { intersection } from '../array';

/**
 * @returns a `boolean` indicating whether an article contains a tag or any provided tag or not.
 * @returns `true` if no tag is provided.
 */
export const hasTag = (
  id?: string | string[] | null
) => (
  { frontmatter: { tags } }: ArticleNode
) => (
  id instanceof Array
    ? !id.length || (intersection(tags, id).length > 0)
    : !id || tags.some((tag) => tag === id)
);

export const getAnchorLink = (anchor: string) => `./#${anchor}`;
